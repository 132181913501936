import React, { useState, useRef, useEffect } from "react";

const MultiSelectDropdown = ({ label = "Select .....", items, selectDefaultValue, onChange , callBack }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState(selectDefaultValue || []);
  const dropdownRef = useRef(null);

  const handleToggleOption = (value) => {
    const isSelected = selectedValues.includes(value);
    if (isSelected) {
      setSelectedValues(selectedValues.filter((val) => val !== value));
    } else {
      setSelectedValues([...selectedValues, value]);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (selectedValues?.length) {
      callBack(selectedValues);
    }
  }, [selectedValues]);

  return (
    <div ref={dropdownRef} className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex justify-between w-full rounded-md border border-gray-300 bg-white px-4 py-2 text-sm leading-5 font-medium text-gray-700 shadow-sm focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
          onClick={toggleDropdown}
          aria-haspopup="true"
          aria-expanded={isOpen}
        >
          {selectedValues?.length ? "Selected" : label}
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10 12.793l-6.146-6.147a1 1 0 00-1.414 1.414l7 7a1 1 0 001.414 0l7-7a1 1 0 00-1.414-1.414L10 12.793z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div className="absolute mt-2 w-full rounded-md bg-white shadow-lg z-10">
          {items?.map((option, index) => (
            <div
              key={index}
              className="rounded-md border border-gray-200"
              onClick={() => handleToggleOption(option.value)}
            >
              <div className="flex items-center justify-between px-4 py-2 cursor-pointer">
                <span className="block text-sm leading-5 text-gray-700">
                  {option?.label}
                </span>
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-ottonomyBlue transition duration-150 ease-in-out"
                  checked={selectedValues.includes(option.value)}
                  onChange={() => {}}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
