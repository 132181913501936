import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DashboardSideBar from "../../subcomponents/DashboardSideBar";
import DashboardBottomBar from "../DashboardBottomBar";
import Header from "../Header";
import { TbEdit } from "react-icons/tb";
import axiosInstance from "../../config/axiosConfig";
import Dropdown from "../Reusable/Dropdown";
import { MdOutlineGroupAdd } from "react-icons/md";
import { genDropDownOptions } from "../../utils/helperFunctions/materiaUI";
import EditUser from "./EditUser";
import AddUser from "./AddUser";
import Loader from "../Reusable/Loader";
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../../redux/slice/userSlice";

function UserManagement(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isopen = useSelector((state) => state.dashboardopenReducer);
  const { sidebarcollapse } = useSelector((state) => state.login);
  const { loading,editUserModalopen,addUserModalopen} = useSelector((state) => state.userReducer);
  const [fleetOptions, setfleetOptions] = useState([]);
  const [userFleetWiseAccess, setUserFleetWiseAccess] = useState([]);
  const [selectedFleet, setSelectedFleet] = useState(null);
  const [isUserAdminOfSelectedFleet, setUserAdminOfSelectedFleet] =
    useState(false);
  const [currentFleetRecord, setCurrentFleetRecord] = useState([]);
  const [isSuperUser, setSuperUser] = useState(false);
  const [editUserModalData, setEditUserModalData] = useState(null);
  const [roleOptions, setRoleOptions] = useState([]);

  const fetchUserFleetList = async () => {
    try {
      const result = await axiosInstance.post("get-user-fleet");
      return result;
    } catch (err) {
      console.error(err);
      toast.error(err.message);
    }
  };
  const fetchRolesList = async () => {
    try {
      const result = await axiosInstance.get("getAllRoles");
      return result;
    } catch (err) {
      console.error(err);
      toast.error(err.message);
    }
  };

  const fetchPermissionFullName = (short) => {
    switch (short) {
      case "um":
        return "User Managment";
      case "to":
        return "Teleoperations";
      case "ob":
        return "Order booking";
      case "mm":
        return "Map managment";
      default:
        return "";
    }
  };
  const fetchRecords = async (params) => {
    setSelectedFleet(params);
    const result = await axiosInstance.get(`users-by-fleet/${params?._id}`);
    const { state, data } = result?.data;
    if (state) {
      setCurrentFleetRecord(data);
    }
  };
  const userRecordsForFleet = async(id) => {
    const result = await axiosInstance.get(`users-by-fleet/${id}`);
    const { state, data } = result?.data;
    if (state) {
      setCurrentFleetRecord(data);
    }
  }
  const closeEditModal = () => {
    dispatch(userActions.setEditUserModal({ value: false }));
  };
  const closeAddModal = () => {
    dispatch(userActions.setAddUserModal({ value: false }));
  };

  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === "null" ||
      localStorage.getItem("token") === undefined
    ) {
      navigate("/");
    }
  }, [navigate]);
  useEffect(() => {
    fetchUserFleetList()
      .then((result) => {
        const { state, data } = result.data;
        if (state) {
          console.log(data);
          const options = data?.role?.map((role) => role.fleetId);
          setfleetOptions(options);
          setUserFleetWiseAccess(data?.role);
          setSuperUser(data?.superUser);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    fetchRolesList()
      .then((result) => {
        const { state, data } = result.data;
        if (state) {
          const options = genDropDownOptions("name", "_id", data);
          setRoleOptions(options);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  // const updateUser = async () => {
  //   const result = fetchUserFleetList()
  //     const { state, data } = result.data;
  //     if (state) {
  //       console.log(data);
  //       const options = data?.role?.map((role) => role.fleetId);
  //       setfleetOptions(options);
  //       setUserFleetWiseAccess(data?.role);
  //       setSuperUser(data?.superUser);
  //     }
  // }

  useEffect(() => {
    if (selectedFleet && userFleetWiseAccess) {
      const filteredFleetAccess = userFleetWiseAccess.find(
        (data) => data?.fleetId?._id === selectedFleet?._id
      );
      if (filteredFleetAccess?.role?.name === "admin" || isSuperUser) {
        setUserAdminOfSelectedFleet(true);
      } else {
        setUserAdminOfSelectedFleet(false);
      }
    }
  }, [isSuperUser, selectedFleet, userFleetWiseAccess]);

  return (
    <>
    {loading && <Loader />}
      <AddUser
        isOpen={addUserModalopen}
        onClose={closeAddModal}
        selectedFleet={selectedFleet}
        roleOptions={roleOptions}
        callBack={userRecordsForFleet}
      />
      <EditUser
        isOpen={editUserModalopen}
        onClose={closeEditModal}
        selectedFleet={selectedFleet}
        userData={editUserModalData}
        roleOptions={roleOptions}
        callBack={userRecordsForFleet}
      />
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div
            className={sidebarcollapse ? "col-md-2 collapseWidth" : "col-md-2"}
          >
            <DashboardSideBar
              dashboardopen={props.dashboardopen}
              active="user-management"
            />
            <DashboardBottomBar active="user-management" />
          </div>

          <div
            className={`${
              sidebarcollapse ? "col-md-10 collapseWidth" : "col-md-10"
            }`}
          >
            <div
              className={
                isopen.userlogindata ? "" : "flex flex-col gap-4 h-full"
              }
            >
              <div className="justify-between Dashboard_page_robot_card_heading_wrapper">
                <h4 className="Dashboard_page_Robot_Card_heading">
                  User Management
                </h4>
                {fleetOptions?.length && (
                  <Dropdown
                    bgColor="ottonomyBlue"
                    textColor="white"
                    buttonLabel="Choose Fleet"
                    items={fleetOptions}
                    callBack={fetchRecords}
                    selectDefaultIndex={true}
                    propertyName="fleetName"
                  />
                )}
              </div>

              <div className="flex flex-col w-full text-white bg-[#282F42] p-3 rounded-lg ">
                {fleetOptions?.length && (
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center justify-between gap-3 pb-1 border-b border-b-ottonomyBlue text-lg font-semibold capitalize font-poppins">
                      {currentFleetRecord && (
                        <p className="text-3xl font-semibold font-poppins text-ottonomyBlue">
                          {currentFleetRecord?.length}
                        </p>
                      )}
                      <p>
                        {selectedFleet?.fleetName && (
                          <span className="text-ottonomyBlue font-bold capitalize text-xl">
                            {" " + selectedFleet?.fleetName}
                          </span>
                        )}
                      </p>
                      <button
                        className="p-2 bg-ottonomyBlue rounded-full hover:bg-[#282F42]"
                        onClick={() => dispatch(userActions.setAddUserModal({ value: true }))}
                      >
                        <MdOutlineGroupAdd />
                      </button>
                    </div>

                    <div className="flex flex-col gap-2 overflow-auto min-h-[400px] max-h-[400px]">
                      {currentFleetRecord && currentFleetRecord.length !== 0 ? (
                        <table className="table-fixed">
                          <thead className="text-[rgb(255,255,255,0.7)] border-b !border-b-[rgb(255,255,255,0.16)] sticky top-0 !bg-[#282F42]">
                            <tr className="font-semibold">
                              <td className="p-3 !text-sm">S.No</td>
                              <td className="p-3 !text-sm">Email Id</td>
                              <td className="p-3 !text-sm">Role</td>
                              <td className="p-3 !text-sm">Permissions</td>
                            </tr>
                          </thead>
                          <tbody>
                            {currentFleetRecord.map((user, index) => (
                              <tr
                                key={"user__list__data__" + user._id}
                                className={`text-white font-light ${
                                  index !== currentFleetRecord.length - 1 &&
                                  "border-b border-[rgb(255,255,255,0.16)]"
                                }`}
                              >
                                <td align="center" className="p-3">
                                  {index + 1}
                                </td>
                                <td align="center" className="p-3">
                                  {user.emailId}
                                </td>
                                <td align="center" className="p-3">
                                  {user?.role[0]?.role?.name}
                                </td>
                                <td align="center" className="p-3 flex gap-x-1">
                                  {user?.role[0]?.role?.permission?.length ? (
                                    user?.role[0]?.role?.permission?.map(
                                      (access, index) => (
                                        <p
                                          className="bg-ottonomyBlue p-2 rounded"
                                          key={index}
                                        >
                                          {fetchPermissionFullName(access.name)}
                                        </p>
                                      )
                                    )
                                  ) : (
                                    <p className="border-2 border-red-800 text-red-800 p-2 rounded">
                                      No Permissions
                                    </p>
                                  )}
                                </td>
                                <td className="p-3">
                                  <div className="flex items-center gap-1">
                                    {!!isUserAdminOfSelectedFleet && (
                                      <TbEdit
                                        className="w-5 h-5 cursor-pointer text-ottonomyBlue"
                                        onClick={() => {
                                          dispatch(userActions.setEditUserModal({ value: true }));
                                          setEditUserModalData(user);
                                        }}
                                      />
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <div className="text-[rgb(255,255,255,0.7)] font-semibold flex flex-grow items-center justify-center w-full h-full ">
                          {" "}
                          No users to show
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserManagement;
