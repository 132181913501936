// userSlice.js
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  loading: false,
  editFleetModalopen : false,
  addFleetModalopen : false
};

const fleetSlice = createSlice({
  name: "fleet",
  initialState,
  reducers: {
    setLoading(state, action) {
      const { loading } = action.payload;
      state.loading = loading;
      state.error = null;
    },
    setEditFleetModal(state, action) {
        const { value } = action.payload;
        state.editFleetModalopen = value;
        state.error = null;
      },
      setAddFleetModal(state, action) {
        const { value } = action.payload;
        state.addFleetModalopen = value;
        state.error = null;
      },
  },
});

export const { actions: fleetActions, reducer: fleetReducer } = fleetSlice;
