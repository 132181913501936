import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axiosInstance from "../../config/axiosConfig";
import Modal from "../Reusable/Modal";
import Dropdown from "../Reusable/Dropdown";
import MultiSelectDropdown from "../Reusable/MultiSelectDropdown";
import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import { fleetActions } from "../../redux/slice/fleetSlice";
import fleetConfig from "../../config/fleetConfig";

const EditFleet = ({ isOpen, onClose, selectedFleet, callBack }) => {
  const [initialValues, setInitialValues] = useState(null);
  const [modalValues, setModalValues] = useState(null);
  const [updateValues, setUpdateValues] = useState({});
  const [isChangeMade, setIsChangeMade] = useState(false);
  const [mapOptions, setMapOptions] = useState([]);
  const { loading, editFleetModalopen } = useSelector(
    (state) => state.fleetReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen && selectedFleet) {
      fetchMapData();
      fetchFleetData();
    } else {
      // Reset state to initial values when modal is closed
      setInitialValues(null);
      setModalValues(null);
      setUpdateValues({});
      setIsChangeMade(false);
      setMapOptions([]);
    }
  }, [isOpen, selectedFleet]);

  useEffect(() => {
    if (mapOptions?.length) {
      const updatedFields = fleetConfig.fields.map((field) => {
        if (field.name === "mapId") {
          return { ...field, options: mapOptions };
        }
        return field;
      });
      fleetConfig.fields = updatedFields;
    }
  }, [mapOptions]);

  const fetchFleetData = async () => {
    dispatch(fleetActions.setLoading({ loading: true }));

    try {
      const response = await axiosInstance.post(
        `get-fleet/${selectedFleet?._id}`
      );
      const { data } = response.data;
      setInitialValues(data);
      setModalValues(data);
    } catch (error) {
      console.error("Error fetching fleet data:", error);
      toast.error("Failed to fetch fleet data");
    } finally {
      dispatch(fleetActions.setLoading({ loading: false }));
    }
  };

  const fetchMapData = async () => {
    try {
      const response = await axiosInstance.get(`map/getMaps`);
      const { data } = response.data;
      if (data?.length) {
        const options = data.map((current) => {
          return {
            label: current?.mapName + `(${current._id})`,
            value: current._id,
            otherKeys: current,
          };
        });
        setMapOptions(options);
      }
    } catch (error) {
      console.error("Error fetching map data:", error);
      toast.error("Failed to fetch map data");
    }
  };

  const handleSubmit = async () => {
    dispatch(fleetActions.setLoading({ loading: true }));
    try {
      await axiosInstance.put(`update-fleet/${selectedFleet?._id}`, updateValues);
      toast.success("Fleet details updated successfully!");
    } catch (error) {
      console.error("Error updating fleet details:", error);
      toast.error("Failed to update fleet details");
    } finally {
      dispatch(fleetActions.setLoading({ loading: false }));
      dispatch(fleetActions.setEditFleetModal({ value: false }));
    }
  };

  const handleInputChange = (value, data, child = null , deSelected = false) => {  
    const trimmedValue = typeof value === "string" ? value.trim() : value;

    setModalValues((prevModalValues) => {
      const updatedModalValues = !child
        ? { ...prevModalValues, [data]: trimmedValue }
        : {
            ...prevModalValues,
            [data]: { ...prevModalValues[data], [child]: trimmedValue },
          };

      const isChanged =
        JSON.stringify(initialValues) !== JSON.stringify(updatedModalValues);
      setIsChangeMade(isChanged);
      return updatedModalValues;
    });

    setUpdateValues((prevValues) => {
      const isDifferent = initialValues && trimmedValue !== initialValues[data];
      const updatedPrevValues = { ...prevValues };
    
      if (!isDifferent) {
        delete updatedPrevValues[data];
      }
    
      return {
        ...(isDifferent ? { ...updatedPrevValues, [data]: trimmedValue } : updatedPrevValues),
        ...(child && isDifferent ? { [data]: { ...updatedPrevValues[data], [child]: trimmedValue } } : {}),
      };
    });
  };

  const handleClose = () => {
    onClose();
    setIsChangeMade(false);
  };

  return (
    <Modal
      isOpen={isOpen && !loading}
      onClose={handleClose}
      onSubmit={handleSubmit}
      textColor="white"
      bgColor="#282F42"
      title="Edit Fleet"
      titleColor="ottonomyBlue"
      submitText="Edit"
      disabled={!isChangeMade}
      s
    >
      <div className="grid grid-cols-2 gap-4 max-h-[500px] overflow-auto">
        {initialValues &&
          fleetConfig?.fields?.map((field, index) => (
            <div key={index} className="flex flex-col gap-y-1">
              <label className="block text-white font-bold mb-2">
                {field.label}
              </label>
              {field.type === "text" && (
                <input
                  type="text"
                  className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline focus:bg-white"
                  value={modalValues[field.name] || ""}
                  onChange={(e) =>
                    handleInputChange(e.target.value, field.name)
                  }
                />
              )}
              {field.type === "checkbox" && (
                <Switch
                  checked={modalValues[field.name]}
                  onChange={(event) =>
                    handleInputChange(event.target.checked, field.name)
                  }
                  inputProps={{ "aria-label": `${field.label} Switch` }}
                  style={{ color: "#fff" }}
                />
              )}
              {field.type === "select" && (
                <Dropdown
                  bgColor="white"
                  textColor="black"
                  buttonLabel={
                    field?.child
                      ? modalValues[field.name][field.child]
                      : modalValues[field.name] || `Choose ${field.label}`
                  }
                  items={field?.options}
                  callBack={(item) => {
                    handleInputChange(item.value, field.name, field.child);
                  }}
                  selectDefaultValue={
                    field?.child
                      ? modalValues[field.name][field.child] ?? null
                      : modalValues[field.name] ?? null
                  }
                  propertyName="label"
                />
              )}
              {field.type === "selectMap" && (
                <Dropdown
                  bgColor="white"
                  textColor="black"
                  buttonLabel={ modalValues[field.name] ? field?.options?.find(map => map.value === modalValues[field.name])?.label : `Choose ${field.label}`
                  }
                  items={field?.options}
                  callBack={(item) => {
                    handleInputChange(item.value, field.name);
                  }}
                  selectDefaultValue={ field?.options?.find(map => map.value === modalValues[field.name])?.label}
                  propertyName="label"
                />
              )}
              {field.type === "multi" && (
                <MultiSelectDropdown
                  bgColor="white"
                  textColor="black"
                  buttonLabel={
                    modalValues[field.name] || `Choose ${field.label}`
                  }
                  label="Select communication"
                  items={field?.options}
                  callBack={(item) => {
                    handleInputChange(item, field.name);
                  }}
                  selectDefaultValue={modalValues[field.name]}
                  propertyName="label"
                />
              )}
            </div>
          ))}
      </div>
    </Modal>
  );
};

export default EditFleet;
