// analyticsConfigSlice

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    distanceUnit : "KM"
};

const analyticsConfigSlice = createSlice({
    name: "analyticsConfig",
    initialState,
    reducers: {
      setDistanceUnit(state, action) {
        const { distanceUnit } = action.payload;
        state.distanceUnit = distanceUnit;
        state.error = null;
      },
    },
  });
  
  export const {actions: analyticsConfigActions, reducer: analyticsReducer } = analyticsConfigSlice;
  

