// userSlice.js
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  loading: false,
  editRobotModalopen : false,
  addRobotModalopen : false
};

const robotSlice = createSlice({
  name: "robot",
  initialState,
  reducers: {
    setLoading(state, action) {
      const { loading } = action.payload;
      state.loading = loading;
      state.error = null;
    },
    setEditRobotModal(state, action) {
        const { value } = action.payload;
        state.editRobotModalopen = value;
        state.error = null;
      },
      setAddRobotModal(state, action) {
        const { value } = action.payload;
        state.addRobotModalopen = value;
        state.error = null;
      },
  },
});

export const { actions: robotActions, reducer: robotReducer } = robotSlice;
