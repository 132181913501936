import { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { toast } from "react-toastify";

mapboxgl.accessToken =
  "pk.eyJ1IjoibXJsYWtzaHlhZ3VwdGEiLCJhIjoiY2xpZWZlcTJjMGo4ZDNkczBwc2ZmcW1qMyJ9.Kurk7BS04invJcCwS0m4Pg";

const isLatitude = (num) => isFinite(num) && Math.abs(num) <= 90;

const isLongitude = (num) => isFinite(num) && Math.abs(num) <= 180;

const OrdersHeatMap = ({
  analyticsData,
  selectedFleetDetails,
  selectedRobotDetails,
}) => {
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(15);

  const fleetData = JSON.parse(localStorage.getItem("fleetData"));

  const mapRef = useRef(null);

  const updateHeatMapData = (data) => {
    const map = mapRef.current;

    if (map.getSource("orders-heatmap-source") && data) {
      let orderLocationData = [];
      if (data.hasOwnProperty("locationCount") && data?.locationCount.length) {
        orderLocationData = data?.locationCount?.map((order, index) => {
          return {
            type: "Feature",
            properties: {
              id: "location__id__" + index,
              // ordersCount: order.uniqueCount,
            },
            geometry: {
              type: "Point",
              coordinates: [order.longitude, order.latitude],
            },
          };
        });
      }

      let clusterSource = map.getSource("orders-heatmap-source");

      map &&
        clusterSource &&
        clusterSource.setData({
          type: "FeatureCollection",
          features: orderLocationData,
        });
      let heatMapCoordinates = [];
      heatMapCoordinates =
        analyticsData?.locationCount &&
        analyticsData?.locationCount.map((order) => {
          if (!isLongitude(order.longitude)) {
            toast.error("Receiving Invalid Longitude for Orders Heat Map!");
            return [];
          }

          if (!isLatitude(order.latitude)) {
            toast.error("Receiving Invalid Latitude for Orders Heat Map!");
            return [];
          }

          return [order.longitude, order.latitude];
        });

      if (
        heatMapCoordinates?.length &&
        !heatMapCoordinates.some(
          (element) => Array.isArray(element) && element.length === 0
        )
      ) {
        // Create a 'LngLatBounds' with both corners at the first coordinate.
        const bounds = new mapboxgl.LngLatBounds(
          heatMapCoordinates[0],
          heatMapCoordinates[0]
        );

        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const coord of heatMapCoordinates) {
          bounds.extend(coord);
        }

        map.fitBounds(bounds, {
          padding: 100,
        });
      }

      // map.addLayer(
      //   {
      //     id: "orders-heatmap-layer",
      //     type: "heatmap",
      //     source: "orders-heatmap-source",
      //     maxzoom: 9,
      //     paint: {
      //       // Increase the heatmap weight based on frequency and property magnitude
      //       "heatmap-weight": [
      //         "interpolate",
      //         ["linear"],
      //         ["get", "ordersCount"],
      //         0,
      //         0,
      //         6,
      //         1,
      //       ],
      //       // Increase the heatmap color weight weight by zoom level
      //       // heatmap-intensity is a multiplier on top of heatmap-weight
      //       "heatmap-intensity": [
      //         "interpolate",
      //         ["linear"],
      //         ["zoom"],
      //         0,
      //         1,
      //         9,
      //         3,
      //       ],
      //       // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
      //       // Begin color ramp at 0-stop with a 0-transparancy color
      //       // to create a blur-like effect.
      //       "heatmap-color": [
      //         "interpolate",
      //         ["linear"],
      //         ["heatmap-density"],
      //         0,
      //         "rgba(33,102,172,0)",
      //         0.2,
      //         "#8978e5",
      //         0.4,
      //         "#e7f312",
      //         0.6,
      //         "rgb(253,219,199)",
      //         0.8,
      //         "#f67200",
      //         1,
      //         "#f51700",
      //       ],
      //       // Adjust the heatmap radius by zoom level
      //       "heatmap-radius": [
      //         "interpolate",
      //         ["linear"],
      //         ["zoom"],
      //         0,
      //         2,
      //         9,
      //         20,
      //       ],
      //       // Transition from heatmap to circle layer by zoom level
      //       "heatmap-opacity": [
      //         "interpolate",
      //         ["linear"],
      //         ["zoom"],
      //         7,
      //         1,
      //         9,
      //         0,
      //       ],
      //     },
      //   }
      //   // "waterway-label"
      // );

      // map.addLayer(
      //   {
      //     id: "orders-heatmap-circle-layer",
      //     type: "circle",
      //     source: "orders-heatmap-source",
      //     minzoom: 7,
      //     paint: {
      //       // Size circle radius by earthquake magnitude and zoom level
      //       "circle-radius": [
      //         "interpolate",
      //         ["linear"],
      //         ["zoom"],
      //         7,
      //         ["interpolate", ["linear"], ["get", "ordersCount"], 1, 1, 6, 4],
      //         16,
      //         [
      //           "interpolate",
      //           ["linear"],
      //           ["get", "ordersCount"],
      //           1,
      //           5,
      //           6,
      //           50,
      //         ],
      //       ],
      //       // Color circle by earthquake magnitude
      //       "circle-color": [
      //         "interpolate",
      //         ["linear"],
      //         ["get", "ordersCount"],
      //         1,
      //         "rgba(33,102,172,0)",
      //         2,
      //         "rgb(103,169,207)",
      //         3,
      //         "rgb(209,229,240)",
      //         4,
      //         "rgb(253,219,199)",
      //         5,
      //         "rgb(239,138,98)",
      //         6,
      //         "rgb(178,24,43)",
      //       ],
      //       "circle-stroke-color": "white",
      //       "circle-stroke-width": 1,
      //       // Transition from heatmap to circle layer by zoom level
      //       "circle-opacity": [
      //         "interpolate",
      //         ["linear"],
      //         ["zoom"],
      //         7,
      //         0,
      //         8,
      //         1,
      //       ],
      //     },
      //     "text-field": ["number-format", ["get", "ordersCount"]],
      //     "text-font": ["Poppins", "Arial Unicode MS Bold"],
      //     "text-size": 12,
      //   }
      //   // "waterway-label"
      // );
    }
  };

  useEffect(() => {
    const initializeMap = () => {
      const newMap = new mapboxgl.Map({
        container: "heat-map-container-id",
        // style: "mapbox://styles/mrlakshyagupta/climr57of00le01pgc0jjgfoh",
        style: "mapbox://styles/mrlakshyagupta/clmribzic029m01qx4biqdw6s",
        // center: [-95.53519703323286, 29.781258912958293],
        center: [fleetData.map.longitude, fleetData.map.latitude],
        zoom: zoom,
        // maxBounds: [
        //   [-95.55892625673143, 29.77473436824925],
        //   [-95.52934215261119, 29.788676939812166],
        // ],
      });

      newMap.on("move", () => {
        setZoom(newMap.getZoom().toFixed(2));
      });

      setMap(newMap);
      mapRef.current = newMap;
    };
    if (!map) {
      initializeMap();
    }
    return () => {};
  }, [
    analyticsData,
    fleetData.map.latitude,
    fleetData.map.longitude,
    map,
    zoom,
  ]);

  useEffect(() => {
    if (map && map.isStyleLoaded()) {
      let orderLocationData = [];
      if (!map.getSource("orders-heatmap-source") && analyticsData) {
        orderLocationData = analyticsData?.locationCount?.map(
          (order, index) => {
            return {
              type: "Feature",
              properties: {
                id: "location__id" + index,
                ordersCount: order.uniqueCount,
              },
              geometry: {
                type: "Point",
                coordinates: [order.longitude, order.latitude],
              },
            };
          }
        );

        map.addSource("orders-heatmap-source", {
          type: "geojson",
          //   data: "https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson",
          data: {
            type: "FeatureCollection",
            features: orderLocationData,
          },
          cluster: true,
          clusterMaxZoom: 20,
          clusterRadius: 50,
        });

        map.addLayer({
          id: "clusters",
          type: "circle",
          source: "orders-heatmap-source",
          filter: ["has", "point_count"],
          paint: {
            "circle-color": [
              "step",
              ["get", "point_count"],
              "#CCF381",
              5,
              "#CBD18F",
              10,
              "#3A6B35",
              20,
              "#CBD18F",
              30,
              "#E2D1F9",
              40,
              "#7E57C2",
              50,
              "#EE4E34",
              60,
              "#EA515A",
              70,
              "#990011",
            ],
            "circle-radius": [
              "step",
              ["get", "point_count"],
              20,
              10,
              30,
              20,
              40,
              30,
              50,
              40,
              60,
              50,
              70,
              60,
              80,
              70,
              90,
              80,
              100,
              90,
              110,
              100,
              120,
              110,
              130,
              120,
              140,
            ],
          },
        });
        map.addLayer({
          id: "unclustered-point",
          type: "circle",
          source: "orders-heatmap-source",
          filter: ["!", ["has", "point_count"]],
          paint: {
            "circle-color": "#11b4da",
            "circle-radius": 20,
            // 'circle-stroke-width': 1,
            // 'circle-stroke-color': '#fff',
          },
        });

        map.addLayer({
          id: "cluster-count",
          type: "symbol",
          source: "orders-heatmap-source",
          layout: {
            "text-field": [
              "coalesce",
              ["get", "point_count_abbreviated"], // If point_count_abbreviated has value, display it
              "1", // Otherwise, display "1"
            ],
            "text-font": ["Poppins Bold", "Arial Unicode MS Bold"],
            "text-size": 18,
          },
          paint: {
            "text-color": "black",
          },
        });

        let heatMapCoordinates = [];
        heatMapCoordinates =
          analyticsData?.locationCount &&
          analyticsData?.locationCount.map((order) => {
            if (!isLongitude(order.longitude)) {
              toast.error("Receiving Invalid Longitude for Orders Heat Map!");
              return [];
            }

            if (!isLatitude(order.latitude)) {
              toast.error("Receiving Invalid Latitude for Orders Heat Map!");
              return [];
            }

            return [order.longitude, order.latitude];
          });

        if (
          heatMapCoordinates?.length &&
          !heatMapCoordinates.some(
            (element) => Array.isArray(element) && element.length === 0
          )
        ) {
          // Create a 'LngLatBounds' with both corners at the first coordinate.
          const bounds = new mapboxgl.LngLatBounds(
            heatMapCoordinates[0],
            heatMapCoordinates[0]
          );

          // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
          for (const coord of heatMapCoordinates) {
            bounds.extend(coord);
          }

          map.fitBounds(bounds, {
            padding: 100,
          });
        }
      }
    }

    if ((selectedFleetDetails || selectedRobotDetails) && analyticsData) {
      updateHeatMapData(analyticsData);
    }
  }, [analyticsData, map, selectedFleetDetails, selectedRobotDetails]);

  return (
    <div className="relative w-full h-full rounded-md">
      <div
        ref={mapRef}
        id="heat-map-container-id"
        className={`w-full h-full`}
      />
    </div>
  );
};

export default OrdersHeatMap;
