import React from "react";

const Loader = ({ customText }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center w-screen h-screen z-20 bg-[rgba(0,0,0,0.8)]">
      <div className="p-3 rounded-lg text-center">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
        {customText && <p className="text-white">{customText}...</p>}
      </div>
    </div>
  );
};

export default Loader;
