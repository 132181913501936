import React, { useState, useEffect, useRef } from "react";
import { TiTick } from "react-icons/ti";

const Dropdown = ({
  bgColor,
  textColor,
  buttonLabel,
  items,
  callBack,
  selectDefaultIndex,
  selectDefaultValue,
  propertyName,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(selectDefaultValue || null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (items?.length && selectDefaultIndex) {
      callBack(items[0]);
    }
  }, []);
  useEffect(() => {
    if(selectDefaultValue){
      setSelectedValue(selectDefaultValue)
    }
  }, [selectDefaultValue] );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    setIsOpen(false);
    // If the clicked item is already selected, deselect it
    setSelectedValue(selectedValue === item[propertyName] ? null : item[propertyName]);
    callBack(item);
  };

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <div>
        <button
          type="button"
          className={`flex justify-between p-2 w-full rounded-md shadow-sm px-4 py-2 bg-${bgColor} text-sm font-medium text-${textColor} focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100`}
          onClick={toggleDropdown}
          aria-haspopup="true"
          aria-expanded={isOpen}
        >
          {buttonLabel}
          <svg
            className={`-mr-1 ml-2 h-5 w-5 transition-transform ${
              isOpen ? "transform rotate-180" : ""
            }`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10 12.793l-6.146-6.147a1 1 0 00-1.414 1.414l7 7a1 1 0 001.414 0l7-7a1 1 0 00-1.414-1.414L10 12.793z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div
          className="origin-top-right absolute right-0 mt-2 w-full sm:w-4/5 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          {items.map((item, index) => (
            <button
              key={index}
              className={`flex px-4 py-2 text-sm text-gray-700 hover:bg-gray-300 justify-between hover:text-gray-900 w-full ${
                item[propertyName] === selectedValue ? 'bg-gray-300 text-gray-900' : ''
              }`}
              role="menuitem"
              onClick={() => handleItemClick(item)}
            >
              <p>{item[propertyName]}</p>
              {item[propertyName] === selectedValue && (
                <TiTick className="self-center h-5 w-5"/>
              )}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
