export const robotConstants = {
    ROBOT_DETAILS_REQUEST: "ROBOT_DETAILS_REQUEST",
    ROBOT_DETAILS_SUCCESS: "ROBOT_DETAILS_SUCCESS",
    ROBOT_DETAILS_FAIL: "ROBOT_DETAILS_FAIL",
  
    ROBOT_STATUS_REQUEST: "ROBOT_STATUS_REQUEST",
    ROBOT_STATUS_SUCCESS: "ROBOT_STATUS_SUCCESS",
    ROBOT_STATUS_FAIL: "ROBOT_STATUS_FAIL",
  
    ROBOT_ACTION_REQUEST: "ROBOT_ACTION_REQUEST",
    ROBOT_ACTION_SUCCESS: "ROBOT_ACTION_SUCCESS",
    ROBOT_ACTION_FAIL: "ROBOT_ACTION_FAIL",
  
    ROBOT_PATH_REQUEST: "ROBOT_PATH_REQUEST",
    ROBOT_PATH_SUCCESS: "ROBOT_PATH_SUCCESS",
    ROBOT_PATH_FAIL: "ROBOT_PATH_FAIL",
  
    ORDER_DETAILS_REQUEST: "ORDER_DETAILS_REQUEST",
    ORDER_DETAILS_SUCCESS: "ORDER_DETAILS_SUCCESS",
    ORDER_DETAILS_FAIL: "ORDER_DETAILS_FAIL",
  
    ROBOT_DIRECTIONS_REQUEST: "ROBOT_DIRECTIONS_REQUEST",
    ROBOT_DIRECTIONS_SUCCESS: "ROBOT_DIRECTIONS_SUCCESS",
    ROBOT_DIRECTIONS_FAIL: "ROBOT_DIRECTIONS_FAIL",
  
    CLEAR_ERRORS: "CLEAR_ERRORS",
  };
  