// userSlice.js
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  loading: false,
  editUserModalopen : false,
  addUserModalopen : false,
  userGroup: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoading(state, action) {
      const { loading } = action.payload;
      state.loading = loading;
      state.error = null;
    },
    setEditUserModal(state, action) {
        const { value } = action.payload;
        state.editUserModalopen = value;
        state.error = null;
      },
      setAddUserModal(state, action) {
        const { value } = action.payload;
        state.addUserModalopen = value;
        state.error = null;
      },
      setUserGroup(state, action) {
        const userGroup = action.payload.data.data;
        state.userGroup = userGroup;
        state.error = null;
      },
  },
});

// Asynchronous action creator
// export const loginUser = (credentials) => async (dispatch) => {
//   dispatch(userActions.loginUserStart());
//   try {
//     const response = await userService.login(credentials);
//     dispatch(userActions.loginUserSuccess(response.data));
//   } catch (error) {
//     dispatch(userActions.loginUserFailure(error.response.data));
//   }
// };

export const { actions: userActions, reducer: userReducer } = userSlice;
