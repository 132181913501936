import { Switch } from "@mui/material";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";
import { updateLocationProperty } from "../../API";
import { toast } from "react-toastify";

const Location = ({
  details,
  setSelectedLocationDetails,
  setEditLocationModalOpen,
  setDeleteLocationModalOpen,
  fetchLocationsByType,
  setLocationsList,
  setEditLocation,
}) => {
  const locationPositionLabelPriority = (position) => {
    console.log(position);
    if (position && position.latitude) {
      return `(Lat,Lng)`;
    }

    else if (position && position.locationName) {
      return ``;
    }


    else if (position && position.positionX) {
      return "X,Y";
    }
  };

  const locationPositionValuePriority = (position) => {
    if (position && position.latitude) {
      return `${position.latitude}, ${position.longitude}`;
    }
    
    else if (position && position.locationName) {
      return position.locationName;
    }


    else if (position && position.positionX) {
      return `${position.positionX}, ${position.positionY}`;
    }
  };

  const handleChangeLocationStatus = (
    locationId,
    locationProperty,
    locationStatusValue
  ) => {
    updateLocationProperty(locationId, locationProperty, locationStatusValue)
      .then((res) => {
        toast.success("Location changed successfully!");
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message)
        // toast.error("Something went wrong!");
      });

      fetchLocationsByType()
      .then((result) => {
        if (result.status === 200) {
          setLocationsList(result?.data?.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    // console.log(locationId, locationProperty, locationStatusValue);
  };

  return (
    <>
      <div className="flex flex-col w-full text-white bg-[#282F42] p-3 rounded-lg ">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-3 pb-1 border-b border-b-ottonomyBlue">
            {details?.locations && details?.locations.length > 0 && (
              <span className="text-3xl font-semibold font-poppins text-ottonomyBlue">
                {details?.locations.length}
              </span>
            )}
            <span className="text-lg font-semibold capitalize font-poppins">
              {details._id} LOCATIONS
            </span>
          </div>

          <div className="flex flex-col gap-2 overflow-auto min-h-[400px] max-h-[400px]">
            {details?.locations && details?.locations.length !== 0 ? (
          //    <div
          //    className={`grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3`}
          //  >
          //    {details?.locations.map.map((location, index) => (
          //        <div
          //          key={"location__no__" + index}
          //          className="flex flex-col justify-evenly font-poppins shadow-[0px_0px_6px_#00000029] rounded-md h-[146px]"
          //        >
          //          <div className="!p-5 h-[70%]">
          //            <span className="text-[22px] uppercase !text-primary font-extrabold">
          //              {" "}
          //              {location.name}
          //            </span>
          //            <div className="flex gap-4">
          //              <div className="flex flex-col text-[12px]">
          //                <span>Assigned Playlist:</span>
          //                <span className="font-bold">
          //                  {screen?.assignid?.name}
          //                </span>
          //              </div>
          //              <div className="flex flex-col text-[12px]">
          //                <span>Robot:</span>
          //                <span className="font-bold">
          //                  {screen?.robot?.name}
          //                </span>
          //              </div>
          //            </div>
          //          </div>
          //          <div className="flex items-center w-full justify-between bg-[#f0f8fa] px-4 relative h-[30%]">
          //            <button
          //              data-modal-target="staticModal"
          //              data-modal-toggle="staticModal"
          //              className="flex items-center gap-1 text-xs cursor-pointer"
          //              onClick={() => handleOpenPreview(screen.assignid._id)}
          //              type="button"
          //            >
          //              <img
          //                src="/assets/images/preview-icon.svg"
          //                alt="preview_icon"
          //              />
          //              Preview{" "}
          //            </button>
          //            <button
          //              className="flex items-center gap-1 text-xs cursor-pointer"
          //              onClick={() => handleEditModal(screen)}
          //            >
          //              <img
          //                src="/assets/images/edit-icon.svg"
          //                alt="edit_icon"
          //              />
          //              Edit{" "}
          //            </button>
          //            <Popover className="relative inline-block">
          //              {({ open }) => (
          //                <>
          //                  <Popover.Button
          //                    className={`text-xs focus:outline-none flex items-center cursor-pointer ${
          //                      open && "!text-primary"
          //                    }`}
          //                    onClick={() => handleboxchangetwo(index)}
          //                  >
          //                    <CiMenuKebab
          //                      style={{ width: "1rem", height: "1rem" }}
          //                    />
          //                    More Actions{" "}
          //                  </Popover.Button>

          //                  <Transition
          //                    as={Fragment}
          //                    // show={open}
          //                    enter="transition ease-out duration-100"
          //                    enterFrom="transform opacity-0 scale-95"
          //                    enterTo="transform opacity-100 scale-100"
          //                    leave="transition ease-in duration-75"
          //                    leaveFrom="transform opacity-100 scale-100"
          //                    leaveTo="transform opacity-0 scale-95"
          //                  >
          //                    <Popover.Panel
          //                      static
          //                      className="absolute z-10 -right-[1rem] mt-1 w-max origin-top-right divide-y divide-gray-100 rounded-md shadow-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          //                    >
          //                      {({ close }) => (
          //                        <div className="px-2 w-max">
          //                          <button
          //                            className={`text-gray-900 group flex w-full px-2 items-center justify-end py-2 text-xs border-b-[1px] border-solid border-b-[#d3d3d3]`}
          //                          >
          //                            <span>Move</span>
          //                          </button>
          //                          <button
          //                            className={`text-gray-900 group flex w-full px-2 items-center justify-end py-2 text-xs border-b-[1px] border-solid border-b-[#d3d3d3]`}
          //                          >
          //                            <span>Move to Onhold Device</span>
          //                          </button>
          //                          <button
          //                            className={`text-gray-900 group flex w-full px-2 items-center justify-end py-2 text-xs border-b-[1px] border-solid border-b-[#d3d3d3]`}
          //                          >
          //                            <span>Move to Team</span>
          //                          </button>
          //                          <button
          //                            className={`text-[#DC6068] group flex w-full px-2 items-center justify-end py-2 text-xs`}
          //                            onClick={() => handleDeleteScreen(screen)}
          //                          >
          //                            <span>Remove</span>
          //                          </button>
          //                        </div>
          //                      )}
          //                    </Popover.Panel>
          //                  </Transition>
          //                </>
          //              )}
          //            </Popover>
          //          </div>
          //        </div>
          //      ))}
          //  </div>
              <table className="table-fixed">
                <thead className="text-[rgb(255,255,255,0.7)] border-b !border-b-[rgb(255,255,255,0.16)] sticky top-0 !bg-[#282F42] z-10">
                  <tr className="font-semibold">
                    <td className="p-3 !text-sm">S.No</td>
                    {details &&
                      details.locations &&
                      !details.locations.some(
                        (location) =>
                          !location.locationName || location.locationName === ""
                      ) && <td className="p-3 !text-sm">Location Name</td>}
                    {details &&
                      details.locations &&
                      !details.locations.some(
                        (location) =>
                          (!location.latitude || location.latitude === "") &&
                          (!location.positionX || location.positionX === "")
                      ) && (
                        <td className="p-3 !text-sm">
                          Position{" "}
                          {locationPositionLabelPriority(details.locations[0])}
                        </td>
                      )}
                    <td className="p-3 !text-sm">Status</td>
                    <td className="p-3 !text-sm">Actions</td>
                  </tr>
                </thead>
                <tbody>
                  {details?.locations.map((location, index) => (
                    <tr
                      key={"location__list__data__" + location._id}
                      className={`text-white font-light ${
                        index !== details?.locations.length - 1 &&
                        "border-b border-[rgb(255,255,255,0.16)]"
                      }`}
                    >
                      <td align="center" className="p-3">
                        {index + 1}
                      </td>
                      {details &&
                        details.locations &&
                        !details.locations.some(
                          (location) =>
                            !location.locationName ||
                            location.locationName === ""
                        ) && <td className="p-3">{location.locationName}</td>}
                      {details &&
                        details.locations &&
                        !details.locations.some(
                          (location) =>
                            (!location.latitude || location.latitude === "") &&
                            (!location.positionX || location.positionX === "")
                        ) && (
                          <td className="p-3">
                            {locationPositionValuePriority(location)}
                          </td>
                        )}
                      <td>
                        <Switch
                          checked={location.status}
                          style={{ color: "#fff" }}
                          onChange={(event, value) => {
                            handleChangeLocationStatus(
                              location._id,
                              "status",
                              value
                            );
                          }}
                        />
                      </td>
                      <td className="p-3">
                        <div className="flex items-center gap-1">
                          <TbEdit
                            className="w-5 h-5 cursor-pointer text-ottonomyBlue"
                            onClick={() => {
                              setEditLocationModalOpen(true);
                              setSelectedLocationDetails(location);
                              setEditLocation(true)
                            }}
                          />
                          <RiDeleteBin6Line
                            className="w-5 h-5 text-red-500 cursor-pointer"
                            onClick={() => {
                              setDeleteLocationModalOpen(true);
                              setSelectedLocationDetails(location);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="text-[rgb(255,255,255,0.7)] font-semibold flex flex-grow items-center justify-center w-full h-full ">
                {" "}
                No Location to show
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Location;
