const fleetConfig = {
    fields: [
      //dropdowns and multiselect dropdowns
      {
        name: 'fleetName',
        label: 'Fleet Name',
        type: 'text',
        required: true
      },
      {
        name: 'webhookURL',
        label: 'Webhook URL',
        type: 'text',
        required: false
      },
      // {
      //   name: 'actionStatusWebhookURL',
      //   label: 'Action Status Webhook URL',
      //   type: 'text',
      //   required: false
      // },
     
      {
        name: 'schedulerURL',
        label: 'Scheduler URL',
        type: 'text',
        required: false
      },
      
      {
        name: 'trackingURL',
        label: 'Tracking URL',
        type: 'text',
        required: false
      },
     
      // {
      //   name: 'fleetWiseOrderFlow',
      //   label: 'Fleet Wise Order Flow',
      //   type: 'text',
      //   required: false
      // },
      {
        name: 'operatorPhone',
        label: 'Operator Phone',
        type: 'text',
        required: false
      },
      {
        name: 'amountRefundDuration',
        label: 'Amount Refund Duration',
        type: 'text',
        required: false
      },
      {
        name: 'supportMail',
        label: 'Support Mail',
        type: 'text',
        required: false
      },
      {
        name: 'feedbackURL',
        label: 'Feedback URL',
        type: 'text',
        required: false
      },
      {
        name: 'mapId',
        childName : 'mapName',
        label: 'Map',
        type: 'selectMap',
        options: [], 
        required: false
      },
      {
        name: 'pinType',
        label: 'Pin Generation Type',
        type: 'select',
        options: [
          { label: 'PHONE', value: 'PHONE' },
          { label: 'ORDERID', value: 'ORDERID' }
        ],
        required: false
      },
      {
        name: 'pinConfig',
        child : 'pinLength',
        label: 'Pin Length',
        type: 'select',
        options: [
          { label: 4, value: 4 },
          { label: 6, value: 6 },
          { label: 8, value: 8 }
        ],
        required: false
      },
      // {
      //   name: 'abbreviation',
      //   label: 'Communication Abbreviation',
      //   type: 'select',
      //   options: [
      //     { label: 'CFA', value: 'CFA' },
      //     { label: 'ELIOR', value: 'ELIOR' },
      //     { label: 'PRINGLE', value: 'PRINGLE' },
      //     { label: 'PRINGLE2', value: 'PRINGLE2' },
      //     { label: 'ARAMEX', value: 'ARAMEX' },
      //     { label: 'SODEXOEUM2', value: 'SODEXOEUM2' },
      //     { label: 'ELIOR2', value: 'ELIOR2' }
      //   ], 
      //   required: false
      // },
      {
        name: 'communication',
        label: 'Communication',
        type: 'multi',
        options: [
          { label: 'SMS', value: 'SMS' },
          { label: 'WEBHOOK', value: 'WEBHOOK' },
          { label: 'EMAIL', value: 'EMAIL' },
          { label: 'PHONE', value: 'PHONE' },
        ],
        required: false
      },
      // {
      //   name: 'schedulerMethod',
      //   label: 'Scheduler Method',
      //   type: 'select',
      //   options: [
      //     { label: 'post', value: 'post' },
      //     { label: 'get', value: 'get' }
      //   ],
      //   required: false
      // },
      //checkboxes
      {
        name: 'status',
        label: 'Status',
        type: 'checkbox',
        required: false
      },
      {
        name: 'orderingStatus',
        label: 'Ordering Status',
        type: 'checkbox',
        required: false
      },
      // {
      //   name: 'closeCabinAuto',
      //   label: 'Close Cabin Auto',
      //   type: 'checkbox',
      //   required: false
      // },
      {
        name: 'dispatchSuggestionsNeeded',
        label: 'Dispatch Suggestions',
        type: 'checkbox',
        required: false
      },
      {
        name: 'autoGenerateOrderId',
        label: 'Auto Generate Order ID',
        type: 'checkbox',
        required: false
      },
      {
        name: 'schedulePickUp',
        label: 'Schedule Pick Up',
        type: 'checkbox',
        required: false
      },
      {
        name: 'preparationNeeded',
        label: 'Preparation Needed',
        type: 'checkbox',
        required: false
      },
      //text fields
    ]
  };
  
  export default fleetConfig;
  