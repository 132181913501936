import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";
import axiosInstance from "../../config/axiosConfig";
import Modal from "../Reusable/Modal";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import { userActions } from "../../redux/slice/userSlice";

const EditUser = ({
  isOpen,
  onClose,
  selectedFleet,
  userData,
  roleOptions,
  callBack,
}) => {
  const [modalValues, setModalValues] = useState(null);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    try {
      if (!modalValues || !modalValues.newRole) {
        toast.error("Please select New role");
        return;
      }
      dispatch(userActions.setLoading({ loading: true }));
      const result = await axiosInstance.put(`update-role`, {
        userId: userData?._id,
        fleetId: selectedFleet?._id,
        roleId: modalValues?.newRole?.value,
      });
      callBack(selectedFleet?._id);
      dispatch(userActions.setEditUserModal({ value: false }));
      dispatch(userActions.setLoading({ loading: false }));
    } catch (err) {
      dispatch(userActions.setEditUserModal({ value: false }));
      dispatch(userActions.setLoading({ loading: false }));
      console.log(err);
      toast.error("Something went wrong");
    }
  };
  const handleInputChange = (value, data) => {
    const input = {};
    input[data] = value;
    setModalValues((prevState) => ({
      ...prevState,
      ...input,
    }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      textColor="white"
      bgColor="#282F42"
      title="Edit User Role"
      titleColor="ottonomyBlue"
      submitText="Edit"
    >
    <div className="flex flex-col gap-y-1">
      <label className={`block text-white font-bold mb-2`}>Email Id</label>
      <input
        type="text"
        className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
        value={userData?.emailId || ""}
      />
      </div>
      <div className="flex flex-col gap-y-1">
      <label className={`block text-white font-bold mb-2`}>Current Role</label>
      <input
        type="text"
        className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
        value={userData?.role[0]?.role?.name || ""}
      />
      </div>
      <div className="flex flex-col gap-y-1">
      <label className="block text-white font-bold mb-2">New Role</label>
      <Autocomplete
        id="role-select"
        options={roleOptions}
        getOptionLabel={(option) => option.label}
        style={{ width: "100%" }}
        onChange={(e, newValue) => handleInputChange(newValue, "newRole")}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            style={{
              borderRadius: 8,
              backgroundColor: "white",
            }}
          />
        )}
      />
      </div>
    </Modal>
  );
};

export default EditUser;
