import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Button, ClickAwayListener, FormControl, IconButton, InputLabel, Menu, MenuItem, Modal, NativeSelect, Select, Switch } from "@mui/material";
import Dropdown from "react-bootstrap/Dropdown";
import OrdersReports from "../subcomponents/OrdersReports";

import { useSelector, useDispatch } from "react-redux";
import { fleetListAndRobotStatusByUserEmail } from "../redux/actions/fleet";
import {
  useHasPermissionInFleet,
  useHasPermissionInAnyFleet,
} from "../customHooks/PermissionHooks";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MissionReports from "../subcomponents/MissionReports";
import { BorderBottom, Label, Settings } from "@mui/icons-material";
import { analyticsConfigActions } from "../redux/slice/analyticsConfigSlice";

const options = [
  "KM",
  "miles"
];

const ITEM_HEIGHT = 20;

function AnalyticsTopBar(props) {
  const { fleetList } = useSelector((state) => state.fleetList);
  const [topbar, settopbar] = useState("order-analytics");
  const fleetData = JSON.parse(localStorage.getItem("fleetData"));
  const canSeeLogs = useHasPermissionInAnyFleet("mission-logs");
  const canSeeOrderAnalytics = useHasPermissionInAnyFleet("order-analytics");
  const emailId = localStorage.getItem("useremail");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedDist, setSelectedDist] = useState( useSelector((state) => state?.analyticsReducer.distanceUnit));
  const open = Boolean(anchorEl);

  const handleanalyticstoggle = (value) => {
    settopbar(value);
  };

  useEffect(() => {
    if (!fleetList || !canSeeOrderAnalytics) {
      if (canSeeLogs) {
        settopbar("mission-logs");
      }
      dispatch(fleetListAndRobotStatusByUserEmail(emailId));
    }
  }, [fleetList, dispatch, emailId, canSeeOrderAnalytics, canSeeLogs]);

  const handleClick = (event) => {
    setAnchorEl( event.currentTarget);
  };
  // const handleClose = ( index) => {
  //   setAnchorEl(null);
  //   setSelectedDist(index);
  //   dispatch(analyticsConfigActions.setDistanceUnit({distanceUnit: options[index]}))
  // };
  const handleChange = (e) => {
    setAnchorEl(null);
    setSelectedDist(e.target.value);
    dispatch(analyticsConfigActions.setDistanceUnit({distanceUnit: e.target.value}))
  };

  return (
    <>
      <div className="Dashboard_page_rightSide_content_heading">
        <div className="flex items-center justify-center h-full cursor-pointer">
          <p className="FleetView_page_haeding_tag">
            {" "}
            <span
              onClick={() => {
                navigate("/fleetPage");
              }}
            >
              Fleet
            </span>{" "}
            <ArrowForwardIosIcon style={{ fontSize: "16px" }} />
            <span className="FleetView_page_Heading_fleetName">
              {props.fleetData
                ? props.fleetData.fleetName
                : fleetData.fleetName}
            </span>
          </p>
        </div>
        <div>
          <div className="FleetPage_top_bar_left_side">
            {canSeeLogs && (
              <div
                className={topbar === "mission-logs" ? "top_bar_active" : ""}
                onClick={() => handleanalyticstoggle("mission-logs")}
              >
                {" "}
                Mission Logs
              </div>
            )}
            {canSeeOrderAnalytics && (
              <div
                className={topbar === "order-analytics" ? "top_bar_active" : ""}
                onClick={() => handleanalyticstoggle("order-analytics")}
              >
                {" "}
                Order Analytics
              </div>
            )}
          </div>
          <Dropdown
            onSelect={(eventKey) => {
              handleanalyticstoggle(eventKey);
            }}
            id="menu_dropdown_select"
          >
            <Dropdown.Toggle id="dropdown-basic">{"Analytics"}</Dropdown.Toggle>
            <Dropdown.Menu className="custom-dropdown-menu">
              {canSeeLogs && (
                <Dropdown.Item key={"mission-logs"} eventKey={"mission-logs"}>
                  Mission Logs
                </Dropdown.Item>
              )}
              {canSeeOrderAnalytics && (
                <Dropdown.Item
                  key={"order-analytics"}
                  eventKey={"order-analytics"}
                >
                  Order Analytics
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="text-white">

            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <Settings style={{color:"white"}}/>
              </IconButton>

              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                  
                }}
                // anchorOrigin={{
                //   vertical: 'bottom',
                //   horizontal: 'left',
                // }}
               
                // MenuProps={{
                //   anchorOrigin: {
                //     vertical: "bottom",
                //     horizontal: "left"
                //   },
                //   transformOrigin: {
                //     vertical: "top",
                //     horizontal: "left"
                //   },
                //   getContentAnchorEl: null
                // }}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                open={open}
                // onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '23.8ch',
                    padding: "0.3rem 1rem",
                    backgroundColor: "#282F42",
                    border: "2px solid rgb(28,30,43)",
                    // backgroundColor: "rgb(28,30,43)",
                    color:"white"
                  },
                }}

                sx={{
                  color: "white",
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#282F42',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#282F42',

                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#282F42',

                  },
                  '.MuiSvgIcon-root ': {
                    fill: "white !important",
                  },

                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#282F42',
                  },
                  }}
              > 
              {/* <FormControl variant="standard"sx={{ m: 1, minWidth: 120 }}> */}
                <div className="flex justify-between items-center text-white">
                  <InputLabel id="demo-simple-select-helper-label" className="h-full text-center pr-1 text-white">Distance Unit : </InputLabel>
                  <Select 
                  value={selectedDist}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  sx={{height: "40px", width: "90px" , color:"white"}}
                  className=""
                >
                  {options.map((option,index) => (
                    // <MenuItem key={option} selected={option === options[selectedDist]} onClick={() => handleClose(index)} className=".Mui-selected" style={{}}>
                    //   {option}
                    // </MenuItem>
                    <MenuItem key={index} value={option} style={{}}>
                      {option}
                    </MenuItem>
                  ))}
                  </Select>
                </div>
                {/* </FormControl> */}
              </Menu>
            </div> 
    
        </div>
      </div>
      {topbar === "mission-logs" ? (
        <MissionReports
          fleetData={props.fleetData ? props.fleetData : fleetData}
        />
      ) : topbar === "order-analytics" ? (
        <OrdersReports
          fleetData={props.fleetData ? props.fleetData : fleetData}
        />
      ) : null}
    </>
  );
}

export default AnalyticsTopBar;
