import { userConstants } from "../constants/user";

export const userLoginReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
    case userConstants.LOAD_USER_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
      };

    case userConstants.LOGIN_SUCCESS:
    case userConstants.LOAD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        user: action.payload,
        sessionDestroyed: false
      };

    case userConstants.LOGOUT_SUCCESS:
      return {
        loading: false,
        user: null,
        sessionDestroyed: true
      };

    case userConstants.LOAD_USER_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        user: null,
        error: action.payload,
      };

    case userConstants.LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        user: null,
        error: action.payload,
      };

    case userConstants.LOGOUT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case userConstants.CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case userConstants.SIDEBAR_COLLAPSE:
      return {
        ...state,
        sidebarcollapse: action.payload,
      };

    case userConstants.DESTROY_SESSION:
      state = {
        sessionDestroyed: true
      };
      return state

    default:
      return state;
  }
};
