import { Box, Modal, useMediaQuery } from "@mui/material";
import { useState } from "react";
import EditLocationForm from "./EditLocationForm";
import Loader from "./Loader";

const editLocationModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#282F42",
  border: "none",
  boxShadow: 24,
  borderRadius: "8px",
};

const EditLocationModal = ({
  locationDetails,
  editLocationModalOpen,
  setEditLocationModalOpen,
  fetchLocationsByType,
  setLocationsList,
  updateLocationGroupIds,
  groupTypeOptions
}) => {
  const [loader, setLoader] = useState(false);

  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Modal
      open={editLocationModalOpen}
      //   onHide={() => setEditLocationModalOpen(false)}
      backdrop="static"
      keyboard="false"
    >
      <Box
        sx={{
          ...editLocationModalStyle,
          width: isMobile ? "100%" : 1000,
          height: isMobile ? "100%" : "auto",
        }}
      >
        {loader ? (
          <div className="flex flex-col items-center justify-center p-4">
            <Loader />
            <span className="text-white">Editing Location...</span>
          </div>
        ) : (
          <div className="Order_modal_wrapper">
            <div className="!p-4 d-flex-center OrderModal_heading_wrapper">
              <span className="Order_modal_h3"> Edit Location</span>
            </div>

            <div>
              <EditLocationForm
                locationDetails={locationDetails}
                setEditLocationModalOpen={setEditLocationModalOpen}
                setLoader={setLoader}
                fetchLocationsByType={fetchLocationsByType}
                setLocationsList={setLocationsList}
                updateLocationGroupIds = {updateLocationGroupIds}
                groupTypeOptions = {groupTypeOptions}
              />
            </div>
          </div>
        )}
      </Box>
    </Modal>
  );
};

export default EditLocationModal;
