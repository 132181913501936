// import { createStore, combineReducers } from 'redux'
import {
    UserloginData, dashboardopenReducer, robotdatareducer, robotlistReducer,
    fleetlatitudeReducer, fleetlongitudeReducer, fleetIdreducer,
    robotMessageReducer, robotidReducer, sshendpointReducer, websocketwsreducer,
    robotStatusDataReducer, robotcommmandlistReducer, robotStatusFleetIdReducer, fleetlistandrobotemailReducer,robotDetailsReducer,orderStatusReducer
    ,robotDirectionsReducer,datefilterreducer
} from './reducers';
import { userReducer } from './slice/userSlice';
import { getFleetListAndRobotStatusByUserEmailReducer } from './reducers/fleet';
import { userLoginReducer } from "./reducers/user"
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { robotReducer } from './slice/robotSlice';
import { fleetReducer } from './slice/fleetSlice';
import permissionReducer  from './slice/permissionSlice';
import { analyticsReducer } from './slice/analyticsConfigSlice';

const initialState = {}

const reducers = combineReducers({
    login: userLoginReducer,
    fleetList: getFleetListAndRobotStatusByUserEmailReducer,
    UserloginData,
    dashboardopenReducer,
    fleetIdreducer,
    robotdatareducer,
    robotMessageReducer,
    robotidReducer,
    robotlistReducer,
    sshendpointReducer,
    fleetlatitudeReducer,
    fleetlongitudeReducer,
    websocketwsreducer,
    robotStatusDataReducer,
    robotcommmandlistReducer,
    robotStatusFleetIdReducer,
    fleetlistandrobotemailReducer,
    robotDetailsReducer,
    orderStatusReducer,
    robotDirectionsReducer,
    datefilterreducer,
    robotReducer,
    fleetReducer,
    userReducer,
    permissionReducer,
    analyticsReducer
})

const store = configureStore({
    reducer: reducers,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });

export default store