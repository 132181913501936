import React from 'react'
// import PersonIcon from '@mui/icons-material/Person';
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {  InputLabel, TextField } from '@mui/material';
// import IconButton from '@mui/material/IconButton';
// import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';
// import { getFleetListAdmin, getFleetListUser, getFleetListuser, login } from '../API/index';
// import { useDispatch } from 'react-redux';
// import { useAuth0 } from '@auth0/auth0-react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {  sendOtpmail } from '../API';

function ForgotPassword() {
    const toastmsg=(msg)=>toast(msg)    
   const token = localStorage.getItem('token')
 

  const isLogin = localStorage.getItem('token')

    const validationSchema = yup.object({
        email: yup
         .string()
         .email('Enter a valid email')
         .required('Email is required'),
     });
   const navigate = useNavigate()
     const formik =useFormik({
       initialValues: {
         email: '',
       },
       validationSchema:validationSchema,
       onSubmit: (values) => {
        sendOtpmail(values.email,token)
        .then((res)=>{
            toastmsg(res.data.message)
            if(res.status===200 || res.status==="200"){
                navigate('/otpverification' , {state:{email:values.email}})
              }    
        })
        .catch((err)=>{
            toastmsg(err.data.message)
        }) 
        
       }    
    })

    return (
      <div className="DesktopMain-wrapper">    
         <div className='Login_page-wholeMain-wrapper'>  
        
          <div className='LoginPage-Main-wrapper'>
          <img src="/assets/images/Ottonomy Logo_White_With_TM-02.png" className='Login-page-form-logo-image'/>
          <form onSubmit={formik.handleSubmit} className="Login-page-form"> 
          {/* <p className="Login-form-p">Login To dashboard</p>
          <h6>Enter you credentials below </h6> */}
        <div className='Loginpage=-main-form'>  
          <div className="Login_page_input_wholeLabel_wrapper"> 
         <InputLabel style={{textAlign:'left',color:'#979FB8',paddingLeft:'25px',marginBottom:'8px',fontSize: '15px'}}> Enter your Email</InputLabel>
            <div className="Loginpage-main-form-inner-div">
                <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.email}
                    name="email"
                    onChange={formik.handleChange}
                    placeholder='Email ID'
                    error={
                        formik.touched.email &&
                        Boolean(formik.errors.email)
                    }
                    helperText={
                        formik.touched.email && formik.errors.email
                    }
                
                    />
            </div>
        </div>   
             
        </div>
        <div className='Loginpage-btn-wrapper'>
           <button type="submit" className='Loginpage-btn-wrapper-btn'>Send OTP</button>
        </div>  
       </form>
    </div>
    {/* {isAuthenticated && 
    (  <>
        {JSON.stringify(user,null,2)}
        </>
     )}
     */}
    </div>
    </div> 

  )
}

export default ForgotPassword