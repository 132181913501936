import { useSelector } from "react-redux";

export const useHasPermissionInAnyFleet = (permission) => {
  const { userPermission }  = useSelector((state) => state.permissionReducer);
  return userPermission?.some(p => p?.role?.permissions?.some(rolePermission => rolePermission?.name === permission)) || false;

}


export const useHasPermissionInFleet = (fleetId, permission) => {
  const { userPermission } = useSelector((state) => state.permissionReducer);
  const fleet = userPermission?.find(p => p?.fleetId?._id === fleetId);
  return fleet ? fleet?.role?.permissions?.some(rolePermission => rolePermission?.name === permission) || false : false;

};
