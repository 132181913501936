import React, { useState, useEffect } from "react";
import { MdOutlineClose } from "react-icons/md";
import { genDropDownOptions } from "../../utils/helperFunctions/materiaUI";
import axiosInstance from "../../config/axiosConfig";
import { toast } from "react-toastify";
import Modal from "../Reusable/Modal";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import { userActions } from "../../redux/slice/userSlice";

const AddUser = ({ isOpen, onClose, selectedFleet, roleOptions, callBack }) => {
  const [addUserModalOptions, setAddUserModalOptions] = useState([]);
  const [modalValues, setModalValues] = useState(null);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    try {
      if (!modalValues || !modalValues.user || !modalValues.newRole) {
        toast.error("Please select both user and role");
        return;
      }
      dispatch(userActions.setLoading({ loading: true }));
      const result = await axiosInstance.put(`assignRole/v2`, {
        userId: modalValues.user.value,
        fleetId: selectedFleet?._id,
        roleId: modalValues.newRole.value,
      });
      callBack(selectedFleet?._id);
      dispatch(userActions.setAddUserModal({ value: false }));
      dispatch(userActions.setLoading({ loading: false }));
      return;
    } catch (err) {
      dispatch(userActions.setAddUserModal({ value: false }));
      dispatch(userActions.setLoading({ loading: false }));
      console.log(err);
      toast.error("Something went wrong");
    }
  };
  const handleInputChange = (value, data) => {
    const input = {};
    input[data] = value;
    setModalValues((prevState) => ({
      ...prevState,
      ...input,
    }));
  };
  const fetchExcludedUsersToAssignRole = async () => {
    try {
      const excludingFleets = [];
      excludingFleets.push(selectedFleet);
      const result = await axiosInstance.post("fetch-all-excluded-users", {
        fleetIds: excludingFleets,
      });
      return result;
    } catch (err) {
      console.error(err);
      toast.error(err.message);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchExcludedUsersToAssignRole()
        .then((result) => {
          const { data, message, state } = result.data;
          if (state) {
            const userOptions = genDropDownOptions("emailId", "_id", data);
            setAddUserModalOptions(userOptions);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleSubmit}
        textColor="white"
        bgColor="#282F42"
        title="Add User"
        titleColor="ottonomyBlue"
        submitText="Submit"
      >
        <label className="block text-white font-bold mb-2">Select User</label>
        <Autocomplete
          id="role-select"
          options={addUserModalOptions}
          getOptionLabel={(option) => option.label}
          style={{ width: "100%" }}
          onChange={(e, newValue) => handleInputChange(newValue, "user")}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{
                borderRadius: 8,
                backgroundColor: "white",
              }}
            />
          )}
        />
        <label className="block text-white font-bold mb-2">Select Role</label>
        <Autocomplete
          id="role-select"
          options={roleOptions}
          getOptionLabel={(option) => option.label}
          style={{ width: "100%" }}
          onChange={(e, newValue) =>
            handleInputChange(newValue, "newRole")
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              style={{
                borderRadius: 8,
                backgroundColor: "white",
              }}
            />
          )}
        />
      </Modal>
    </>
  );
};

export default AddUser;
