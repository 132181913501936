import { Box, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getrobotStatus, handleteleopAckinterrupt, handleteleopComplete } from '../API'
import ringer from "../components/alert.wav";

function TeleopCompleted(props) {
    const audio = new Audio(ringer);
    const TeleOpCompletedError=()=> toast.error('TeleOp Completed Failed',{
      position: toast.POSITION.BOTTOM_RIGHT
  })
    const TeleOpCompletedSuccess=()=>toast.success('Teleop Completed Successfully',{
      position: toast.POSITION.BOTTOM_RIGHT
  })
    const TeleOpAckError=()=> toast.error('TeleOp Acknowledge Failed',{
      position: toast.POSITION.BOTTOM_RIGHT
  })
    const TeleOpSuccess=()=>toast.success('Teleop Acknowledge Successfully',{
      position: toast.POSITION.BOTTOM_RIGHT
  })
    const [completeddisabled, setcompleteddisabled] =useState(true)
    const [completeddisabledinner, setcompleteddisabledinner] =useState(false)
    const [lastcheck, setlastcheck] =useState(true)
    const token = localStorage.getItem('token')
    const [isOpen, setIsOpen] =useState()  
    const [open, setOpen] = React.useState(false);
    const [sound,setsound] = useState(false)
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 825,
      bgcolor: '#282F42',
      p: 4,
      borderRadius: "11px",
      boxShadow: "0px 0px 20px rgb(0 0 0 / 40%)"
    };
    const playbeepsound=()=>{

      function play(){
       
        audio.play() 
        audio.loop = true
        audio.muted =false
        audio.autoplay=true
        // console.log(';sdsbfbndn audio')
      } 
       play() 
     }
     const pausebeepsound=()=>{
     
      function pause(){
      
        audio.pause() 
        audio.loop = false
        audio.muted =true
        audio.src=''
        // console.log('ELement',document.getElementsByTagName('audio')[0].attributes('src'))
        // console.log('sdsbfbndn pause',audio)
      } 
        pause() 
     }
    useEffect(()=>{
      // document.addEventListener('click', function(){
      //   document.getElementById('playaudio').play();
      //   }) 
   
      //  setInterval(()=>{
      //   getrobotStatus(props.robotId,token)
      //   .then((res)=>{
      //     // console.log('res?.data?.data?.robotStatus?.teleop',res?.data?.data?.robotStatus?.teleop)
      //     // console.log('Robot Status Teleop Completed  Response',res?.data?.data?.robotStatus?.robotRunningState)
      if((props?.robotData?.robotStatus?.robotRunningState==='TELE_OP_MOVING' || props?.robotData?.robotStatus?.robotRunningState==='TELE_OP' || props?.robotData?.robotStatus?.robotRunningState==='TELE_OP_OBSTACLE') 
      // && props?.robotData?.robotStatus?.teleop==="YES"
      ){
        setlastcheck(false)
        setcompleteddisabled(false)
      }
      else if((props?.robotData?.robotStatus?.robotRunningState==='AUTO_RUN_MOVING' || props?.robotData?.robotStatus?.robotRunningState==='AUTO_RUN'|| props?.robotData?.robotStatus?.robotRunningState==='AUTO_OBSTACLE' || props?.robotData?.robotStatus?.robotRunningState==='MANUAL') && props?.robotData?.robotStatus?.teleop==="YES"){
        // setcompleteddisabled(true)
        // playbeepsound()
        // console.log('Inside teleop Ack',props?.robotData)
        setcompleteddisabled(true)
        setlastcheck(false)
        // handleOpen()  
      }
      else if(props?.robotData?.robotStatus?.teleop===null || props?.robotData?.robotStatus?.teleop===undefined) {
        setlastcheck(true)
        // console.log('sfsffsbdfbfbfbdfbdfbdbfbf')
      }    
      else{
        setlastcheck(true)
      }
          
      //   })
      //   .catch((err)=>{
      //       // console.log('Robot Status Open Ssh Error',err)
      //   })
      //  },8000)
      
},[props])


  
const handleteleopAckfunction=(robotId)=>{
    handleteleopAckinterrupt(robotId).then((res)=>{
      TeleOpSuccess() 
      setcompleteddisabled(true)
      setlastcheck(true)
      pausebeepsound()
    })
    .catch((err)=>{
      TeleOpAckError()
      })
  }
    const handleteleopCompletefunction=(robotId)=>{
        handleteleopComplete(robotId).then((res)=>{
          TeleOpCompletedSuccess()
          setcompleteddisabledinner(true)
        })
        .catch((err)=>{
          TeleOpCompletedError()
        })
      }
      const handleOpen = () => {
        setOpen(true);
      }
      const handleClose = (reason) => {
        if (reason && reason == "backdropClick") 
            return;
         setOpen(false);
      }
  return (
    <>
    <Modal
        backdrop="static"
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
           <p className='Modal-wrapper-p'> 
           {props.robotId} robot Required Teleop Ack 
          </p>
         <div className='Modal-buttons-wrapper d-flex-center-center'> 
           
           <button className="Modal-btn Modal-btn-red" onClick={()=>{
             handleteleopAckfunction(props?.robotId)
             handleClose()}
           }> Teleop Ack</button>
           <button className="Modal-btn" onClick={handleClose}> No</button> 
          </div>
    
        </Box>
      </Modal>
    {lastcheck ? null :
    <>  {completeddisabled ? 
        <> 
         <button className={"Dashboard-page-teleop-button" } >Teleop Ack 
         {/* onClick={()=>handleteleopAckfunction(props?.robotId)} */}
         </button>  
         <audio preload="auto" loop autoplay="autoplay" id="playaudio">
           <source src="/static/media/alert.aa9b8554cbeaf7968e81.wav" type="audio/wav"></source>
         </audio>         
        </>:
        <>
         
        <button  className={!completeddisabled ? "Dashboard-page-teleop-button Teleop_completed_btn":"Dashboard-page-teleop-button-disabled" }>
          
           Teleop Completed
           {/* onClick={()=>handleteleopCompletefunction(props?.robotId)} */}
           </button>
        </>
        
        }</>
    }
  
    </>
  )
}

export default React.memo(TeleopCompleted)