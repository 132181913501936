import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import { fleetData } from '../Data/FleetData'
import DashboardSideBar from './DashboardSideBar'
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { deletefleet, fleetlist } from '../API';
import { useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DashboardBottomBar from '../components/DashboardBottomBar';

function IndividualUsers(props) {
  const navigate =useNavigate()
  const individualuserData = useLocation()
  const [fleetlistData, setfleetlistData] =useState()
  const token =localStorage.getItem('token')
    useEffect(()=>{
        fleetlist(individualuserData.state.data.emailId,token).then((res)=>{
        setfleetlistData(res.data.fleet)
        console.log('Fleet page response',res)
        }).catch((err)=>{
            console.log('Fleet page error',err)
        })
    },[])
    const isopen = useSelector(state=>state.dashboardopenReducer)  
    const handlerolesubmit=()=>{
    console.log('fleetlistData',fleetlistData)
    }
  const handlerolechange=(e,item)=>{
    
if(e.target.checked){
   item.role.push(e.target.name)
}
else if(!e.target.checked){
    item.role = item.role.filter(itm=>itm !== e.target.name)
}
    
  }
 
    return (
  <>
 
    <Header/>

   <div className="Fleet_page_Whole_content_wrapper_main"> 
             <DashboardSideBar dashboardopen={props.dashboardopen} active="userlist"/>
             <DashboardBottomBar active="userlist" />

    <div className={isopen.userlogindata  ?'Dashboard_page_right_side_fleetpage margin_left_close':'Dashboard_page_right_side_fleetpage margin_left' }>
       <h4 className="Dashboard_page_Robot_Card_heading"> <Link style={{color:'white', textDecoration:'none'}} to="/userlist">User</Link> <ArrowForwardIosIcon /> <span> {individualuserData.state.data.firstName}&emsp;{individualuserData.state.data.lastName}</span> </h4>
        <table style={{width:'100%'}} className="Fleet_page_table_Main_whole"> 
           <thead> 
             <tr>
              <th> Fleet </th>
              <th> Role </th>
            </tr>
           </thead>
          <tbody> 
            {(()=>{
                   fleetlistData?.map((item)=>{
                       item.role=[]
                   })
            })()}
            {fleetlistData?.map(item=>{
                return (
                <tr> 
                   <td> {item.fleetName}</td>
                <td> 
                    <div className="Individual_User_page_row_wrapper"> 
                       <div> 
                       <FormGroup>
                            <FormControlLabel className="Individual_UserPage_Input_checkbox_wrapper" control={<Checkbox name="superadmin" onChange={(e)=>handlerolechange(e,item)} className="Individual_userCheck_box_input"/>} label="superadmin" />
                      </FormGroup>
                      </div>
                      <div>
                       <FormGroup>
                            <FormControlLabel className="Individual_UserPage_Input_checkbox_wrapper" control={<Checkbox name="admin" onChange={(e)=>handlerolechange(e,item)} className="Individual_userCheck_box_input"/>} label="admin" />
                      </FormGroup>
                      </div>
                      <div>
                      <FormGroup>
                            <FormControlLabel className="Individual_UserPage_Input_checkbox_wrapper" control={<Checkbox name="Fleetadmin" onChange={(e)=>handlerolechange(e,item)} className="Individual_userCheck_box_input"/>} label="Fleetadmin" />
                      </FormGroup>
                      </div>
                      <div> 
                       <FormGroup>
                            <FormControlLabel className="Individual_UserPage_Input_checkbox_wrapper" control={<Checkbox name="Teleop" onChange={(e)=>handlerolechange(e,item)} className="Individual_userCheck_box_input"/>} label="Teleop" />
                      </FormGroup>
                       </div>
                    </div>
                </td>
              </tr>
                )
            })}  
            </tbody> 
        </table>
      </div>  
     </div>
      <div className="AddFleet_page_save_button_wrapper Individual_user_page"> 
            <button type="submit" onClick={handlerolesubmit}> Save</button>
    </div>
    {/* <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon style={{}}/>}
        className="FleetPage_plusIcon"
       
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
          />
        ))}
      </SpeedDial > */}
    </>
  )
}

export default IndividualUsers
