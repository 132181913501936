import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import LockIcon from "@mui/icons-material/Lock";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#282F42",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

function EditPasswordModal({ isOpen, onClose }) {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const token = localStorage.getItem("token");

  const handleChangePassword = async () => {
    if (!currentPassword.trim()) {
      toast.error("Please enter your current password");
      return;
    }

    if (!newPassword.trim()) {
      toast.error("Please enter a new password");
      return;
    }

    if (newPassword.trim().length < 3) {
      toast.error("New password must be at least 3 characters long");
      return;
    }

    try {
      const changePasswordAction = await axios.post(
        `${process.env.REACT_APP_BASE_URL}change-password`,
        {
          oldPassword: currentPassword,
          newPassword: newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const { state, data } = changePasswordAction.data;
      if (state) {
        setCurrentPassword("");
        setNewPassword("");
        onClose();
        toast.success("Password changed successfully!");
      } else {
        toast.error("Something went wrong!");
      }
    } catch (err) {
      toast.error("Incorrect current Password");
    }
  };
  const handleModalClose = () => {
    setShowCurrentPassword(false);
    setShowNewPassword(false);
    setCurrentPassword("");
    setNewPassword("");
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      backdrop="static"
      keyboard="false"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="edit-password-modal-main-wrapper">
          <h3 className="text-ottonomyBlue font-bold text-center">
            Change Password
          </h3>
          <div className="flex flex-col gap-y-2 text-white">
            <div className="flex flex-col gap-y-2">
              <label htmlFor="currentPassword" className="edit-password-label">
                Current Password
              </label>
              <div className="relative">
                <input
                  type={showCurrentPassword ? "text" : "password"}
                  id="currentPassword"
                  name="currentPassword"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="bg-[#1c1e2b] text-white rounded p-2 w-full"
                />
                <button
                  className="absolute top-1/2 right-3 transform -translate-y-1/2 text-white"
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                >
                  {showCurrentPassword ? <FaEye /> : <FaEyeSlash />}
                </button>
              </div>
            </div>
            <div className="flex flex-col gap-y-2">
              <label htmlFor="newPassword" className="edit-password-label">
                New Password
              </label>
              <div className="relative">
                <input
                  type={showNewPassword ? "text" : "password"}
                  id="newPassword"
                  name="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="bg-[#1c1e2b] text-white rounded p-2 w-full"
                />
                <button
                  className="absolute top-1/2 right-3 transform -translate-y-1/2 text-white"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {showNewPassword ? <FaEye /> : <FaEyeSlash />}
                </button>
              </div>
            </div>
          </div>
          <div className="edit-password-modal-buttons flex justify-between pt-3">
            <button
              className="bg-ottonomyBlue text-white p-2 rounded font-bold hover:bg-[#1c1e2b]"
              onClick={handleModalClose}
            >
              Cancel
            </button>
            <button
              className="bg-ottonomyBlue text-white p-2 rounded font-bold hover:bg-[#1c1e2b]"
              onClick={handleChangePassword}
            >
              Change
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default React.memo(EditPasswordModal);
