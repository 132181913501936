import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { memo } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 430,
  bgcolor: "#282F42",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

function CancelOrderConfirmModal({
  confirmationCancelOrder,
  setConfirmationCancelOrder,
  selectedCancelOrderId,
  setSelectedCancelOrderId,
  changeOrderCancTime,
}) {
  const handleClose = (reason) => {
    if (reason && reason === "backdropClick") return;
    setConfirmationCancelOrder(false);
    setSelectedCancelOrderId(null);
  };

  return (
    <>
      <Modal
        open={confirmationCancelOrder}
        // BackdropProps={false}
        onHide={handleClose}
        // onClose={handleClose}
        backdrop="static"
        keyboard="false"
      >
        <Box sx={style}>
          <div className="Cancel-order-Modal-Mainwrapper">
            <p> Are you sure want to cancel this order ?</p>
            <div className="Modal-button-Main-wrapper">
              <Button
                className="Modal-button-Main-btn yes-red-active"
                onClick={() => {
                  handleClose();
                  changeOrderCancTime(selectedCancelOrderId, true);
                }}
              >
                {" "}
                Yes
              </Button>
              <Button
                className="Modal-button-Main-btn color-white"
                onClick={handleClose}
              >
                {" "}
                No
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
export default memo(CancelOrderConfirmModal);
