import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../config/axiosConfig";

const initialState = {
  userPermission: [],
  loading: false,
  error: null,
};

export const fetchUserPermissions = createAsyncThunk(
  "permission/fetchUserPermissions",
  async () => {
    const response = await axiosInstance.post("get-user-fleet");
    return response.data;
  }
);

const permissionSlice = createSlice({
  name: "permission",
  initialState,
  reducers: {
    setUserPermission(state, action) {
      state.userPermission = action.payload;
    },
    emptyUserPermission(state) {
      state.userPermission = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserPermissions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserPermissions.fulfilled, (state, action) => {
        state.loading = false;
        state.userPermission = action?.payload?.data?.role;
      })
      .addCase(fetchUserPermissions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setUserPermission, emptyUserPermission } = permissionSlice.actions;
export default permissionSlice.reducer;
