import React from 'react'
import { useSelector } from 'react-redux'
import Header from '../components/Header'
import DashboardSideBar from './DashboardSideBar'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TextField } from '@mui/material';
import { addfleet, addUser, editUser } from '../API';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DashboardBottomBar from '../components/DashboardBottomBar';

function EditUser(props) {
    const isopen = useSelector(state=>state.dashboardopenReducer) 
    const token =localStorage.getItem('token')
    const toastmsg=(msg)=>toast(msg)
    const validationSchema = yup.object({
         emailId:yup.string().required('User Name is required'),
         pin: yup.string().required('Pin is required'),
         firstName:yup.string().required('First Name is required'),
         lastName:yup.string().required('Last Name is required'),
         contactNumber:yup.number().required('Contact Number is required')
     });

     const navigate = useNavigate()
     const userData= useLocation()
    const formik =useFormik({
          initialValues: {
            emailId:userData.state.data.username,
            pin:userData.state.data.pin,
            firstName:userData.state.data.firstName,
            lastName:userData.state.data.lastName,
            contactNumber:userData.state.data.contactNumber     
         },
        validationSchema:validationSchema,
        onSubmit: (values) => {
            editUser(values, token, userData.state.data._id).then((res)=>{
                toastmsg(res.data.message)
                setTimeout(()=>{
                    navigate('/userlist')
                },2000)
              
            }).catch((err)=>{
                toastmsg(err.data.message)
            })
        }    
     })
    return (
    <>
    <Header/>
    <div className="container-fluid">
        <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-10">
            <div style={{height:'100%'}} className={isopen.userlogindata  ?'':'' }>
            <div className='Dashboard_page_rightSide_content_heading'>
            <h4 className="Dashboard_page_Robot_Card_heading">   Edit User </h4>
                </div>
       
       <div className='AddFleet_page_content'> 
          <form onSubmit={formik.handleSubmit} >
            <div className="AddFleet_form_field_wrapper"> 
             <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.emailId}
                    name="emailId"
                    onChange={formik.handleChange}
                    placeholder='Email'
                    label="Email"
                    error={
                        formik.touched.emailId &&
                        Boolean(formik.errors.emailId)
                    }
                    helperText={
                        formik.touched.emailId && formik.errors.emailId
                    }
                   style={{margin:'15px'}}
                    className="Text_Field_Input_class"

                    />
                <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.pin}
                    name="pin"
                    onChange={formik.handleChange}
                    placeholder='Pin'
                    label="Pin"
                    error={
                        formik.touched.pin &&
                        Boolean(formik.errors.pin)
                    }
                    helperText={
                        formik.touched.pin && formik.errors.pin
                    }
                    style={{margin:'15px'}}
                     className="Text_Field_Input_class"
                    />      
            </div>
            <div className="AddFleet_form_field_wrapper"> 
             <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.firstName}
                    name="firstName"
                    onChange={formik.handleChange}
                    placeholder='First Name'
                    label="First Name"
                    error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                    }
                    helperText={
                        formik.touched.firstName && formik.errors.firstName
                    }
                    style={{margin:'15px'}}
                     className="Text_Field_Input_class"
                    />
                <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.lastName}
                    name="lastName"
                    onChange={formik.handleChange}
                    placeholder='Last Name'
                    label="Last Name"
                    error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                    }
                    helperText={
                        formik.touched.lastName && formik.errors.lastName
                    }
                    style={{margin:'15px'}}
                     className="Text_Field_Input_class"
                    />      
            </div>
            <div className="AddFleet_form_field_wrapper"> 
             <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.contactNumber}
                    name="contactNumber"
                    onChange={formik.handleChange}
                    placeholder='Contact Number'
                    label="Contact Number"
                    error={
                        formik.touched.contactNumber &&
                        Boolean(formik.errors.contactNumber)
                    }
                    helperText={
                        formik.touched.contactNumber && formik.errors.contactNumber
                    }
                    style={{margin:'15px'}}
                     className="Text_Field_Input_class"
                    />
            </div>
            <div className="AddFleet_page_save_button_wrapper"> 
            <button type="submit"> Save</button>
            </div>
        </form>   
       </div>
     </div>
            </div>
    </div></div>
  <div className="Fleet_page_Whole_content_wrapper_main">
 
    <DashboardSideBar dashboardopen={props.dashboardopen} active="userlist"/>
    <DashboardBottomBar active="userlist" />
    
     </div>
    </>
       )
}

export default EditUser
